import { Injectable, SecurityContext } from '@angular/core';
import * as uuid from 'uuid';
import { DomSanitizer } from '@angular/platform-browser';
import { ZendeskHandlerData, RHInit } from '../model/Session';
import { Message, MessageType } from '../model/message';
import { WidgetComponent } from '../widget/widget.component';
import { ActorType, User } from '../model/user';
import { BackendService } from './backend.service';
import { MultiTabService } from './multi-tab.service';
import { LoggerService } from './logger/logger.service';

export interface ZDepartment {
  id: number;
  name: string;
  status: 'online' | 'offline';
}

type ZChatEvent =
  | 'chat.msg'
  | 'chat.file'
  | 'chat.queue_position'
  | 'chat.memberjoin'
  | 'chat.memberleave'
  | 'chat.request.rating'
  | 'chat.rating'
  | 'chat.comment'
  | 'typing'
  | 'last_read';
interface ZChatEventPayload {
  type: ZChatEvent;
}
interface ZMessage extends ZChatEventPayload {
  type: 'chat.msg';
  nick: string;
  display_name: string;
  timestamp: number;
  msg: string;
  options: string[];
  structured_msg: any;
}
interface ZFile extends ZChatEventPayload {
  type: 'chat.file';
  nick: string;
  display_name: string;
  timestamp: number;
  attachment: ZAttachment;
  deleted: boolean;
}
interface ZAttachment {
  metadata: ZMetadata | undefined;
  mime_type: string;
  name: string;
  size: number;
  url: string;
}
interface ZMetadata {
  width: number;
  height: number;
}
interface ZTyping extends ZChatEventPayload {
  type: 'typing';
  nick: string;
  typing: boolean;
}
interface ZQueuePosition extends ZChatEventPayload {
  type: 'chat.queue_position';
  nick: 'system:queue';
  queue_position: number;
}

interface ZAgentInfo {
  nick: string;
  display_name: string;
  avatar_path: string;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class RedirectZendeskService {
  public data: { accountKey: string; department?: number };
  public user: User = {
    email: '',
    firstName: 'Agent',
    lastName: 'Zendesk',
    type: ActorType.THIRD_PARTY,
    id: 'act_00000000-0000-0000-0000-000000000000',
    avatar: 'assets/leadAvatar.png',
    customData: null,
  };

  public widget: WidgetComponent;

  constructor(
    private logger: LoggerService,
    public backendService: BackendService,
    public multiTabService: MultiTabService,
    public domSanitizer: DomSanitizer,
  ) {
    const listener = (event: MessageEvent) => {
      if (event.data.type !== 'bc_zChat') {
        return;
      }
      const eventType: string = event.data.data.type;
      const eventData: any = event.data.data.data;
      switch (eventType) {
        case 'messageEvent':
          this.messageEventHandler(eventData);
          break;
        case 'fileMessageEvent':
          this.fileEventHandler(eventData);
          break;
        case 'queueEvent':
          this.queueEventHandler(eventData);
          break;
        case 'agentIdentity':
          this.agentIdentityHandler(eventData);
          break;
        case 'typingEvent':
          this.typingEvent(eventData);
          break;
        default:
      }
    };

    window.addEventListener('message', listener, false);
  }

  public init(initJson: RHInit, widget: WidgetComponent) {
    this.data = initJson.data;
    this.user = initJson.actor;
    this.widget = widget;

    parent.window.postMessage(
      {
        type: 'bc_zChat',
        data: {
          type: 'init',
          data: {
            initData: this.data,
            user: this.user,
          },
        },
      },
      '*',
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public hrefChanged(href: string, pageName: string) {}

  public onMessage(message: Message) {
    // A new message arrives from botmind socket
    // No need to transmit messages that arrived through zChat
    if (message.author.id === this.user.id) {
      return;
    }
    if (message.author.id === this.widget.user.id) {
      // If message is from visitor
      parent.window.postMessage({ type: 'bc_zChat', data: { type: 'onMessage', data: message } }, '*');
    }
  }

  public addTag(tags: string[]) {
    parent.window.postMessage({ type: 'bc_zChat', data: { type: 'onNewTag', data: { tags } } }, '*');
  }

  public onRhDataChanged(rhData: ZendeskHandlerData | null) {
    parent.window.postMessage({ type: 'bc_zChat', data: { type: 'onRhDataChanged', data: rhData } }, '*');
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private messageEventHandler(data: { message: ZMessage; pageUrl: string; pageTitle: string }) {
    if (data.message.nick === 'visitor') {
      // If it is a message from the visitor, do nothing
      return;
    }
    const content = this.domSanitizer.sanitize(SecurityContext.HTML, data.message.msg);
    const socketMessage: Message = {
      author: this.user,
      type: MessageType.TEXT,
      sentDate: data.message.timestamp,
      updatedDate: data.message.timestamp,
      uuid: uuid.v3(String(data.message.timestamp), this.widget.session.id),
      content,
      delivered: false,
      noNotif: false,
      isWelcome: false,
      isEngage: false,
      popNotif: false,
      isButtonReply: false,
      isWelcomeEngageButtonReply: false,
      isLastStep: false,
      schemaSatisfaction: null,
      showSchemaSatisfaction: false,
      webchatMessageId: null,
    };
    if (this.multiTabService.getIsActiveTab()) {
      this.addMessage(socketMessage, data.pageUrl, data.pageTitle);
    }
  }

  private addMessage(socketMessage: Message, pageUrl: string, pageTitle: string) {
    if (this.widget.pushMessage(socketMessage, false, false)) {
      void this.widget.setSelfCareActionButtonsDisplayedStatus(false);
      if (!this.widget.ghostMode) {
        void this.backendService.sendMessage(socketMessage, pageUrl, pageTitle, true).then(async (message) => {
          this.widget.displayService.onNewMessage(message, this.widget.session.dismissedPopUp);
        });
      } else this.logger.warn('RZS: tried to send message on ghost');
    }
  }

  private fileEventHandler(data: { message: ZFile; pageUrl: string; pageTitle: string }) {
    if (data.message.nick === 'visitor') {
      return;
    }
    const type = data.message.attachment.mime_type;
    const url = this.domSanitizer.sanitize(SecurityContext.HTML, data.message.attachment.url);
    if (type.toLowerCase() === 'application/pdf') {
      const socketMessage: Message = {
        author: this.user,
        type: MessageType.ATTACHMENT,
        sentDate: data.message.timestamp,
        updatedDate: data.message.timestamp,
        uuid: uuid.v3(String(data.message.timestamp), this.widget.session.id),
        content: {
          url,
          contentType: 'pdf',
        },
        delivered: false,
        noNotif: false,
        isWelcome: false,
        isEngage: false,
        popNotif: false,
        isButtonReply: false,
        isWelcomeEngageButtonReply: false,
        isLastStep: false,
        schemaSatisfaction: null,
        showSchemaSatisfaction: false,
        webchatMessageId: null,
      };
      if (this.multiTabService.getIsActiveTab()) {
        this.addMessage(socketMessage, data.pageUrl, data.pageTitle);
      }
    } else if (type.toLowerCase().split('/')[0] === 'image') {
      const socketMessage: Message = {
        author: this.user,
        type: MessageType.IMAGE,
        sentDate: data.message.timestamp,
        updatedDate: data.message.timestamp,
        uuid: uuid.v3(String(data.message.timestamp), this.widget.session.id),
        content: url,
        delivered: false,
        noNotif: false,
        isWelcome: false,
        isEngage: false,
        popNotif: false,
        isButtonReply: false,
        isWelcomeEngageButtonReply: false,
        isLastStep: false,
        schemaSatisfaction: null,
        showSchemaSatisfaction: false,
        webchatMessageId: null,
      };
      if (this.multiTabService.getIsActiveTab()) {
        this.addMessage(socketMessage, data.pageUrl, data.pageTitle);
      }
    }
  }

  private queueEventHandler(data: ZQueuePosition) {
    void this.widget.setThirdPartyQueuePosition(data.queue_position);
  }

  private agentIdentityHandler(data: ZAgentInfo) {
    void this.widget.setApparentAgentInfo(this.user.id, {
      nickName: data.display_name,
      avatarUrl: data.avatar_path,
    });
  }

  private typingEvent(data: ZTyping) {
    if (this.multiTabService.getIsActiveTab()) {
      if (!this.widget.ghostMode) {
        void this.backendService.sendZendeskIsTyping(data.typing, this.user);
      } else this.logger.warn('RZS: tried to send isTyping on ghost');
    }
  }

  public sendHistory(messages: Message[], ip?: string) {
    const data = { messages, ip: ip || null };
    parent.window.postMessage(
      {
        type: 'bc_zChat',
        data: { type: 'sendHistory', data },
      },
      '*',
    );
  }

  public setUser(user: User) {
    parent.window.postMessage(
      {
        type: 'bc_zChat',
        data: { type: 'setVisitor', data: user },
      },
      '*',
    );
  }

  public visitorTyping(isTyping: boolean) {
    parent.window.postMessage(
      {
        type: 'bc_zChat',
        data: {
          type: 'isTyping',
          data: isTyping,
        },
      },
      '*',
    );
  }

  public endRedirection() {
    parent.window.postMessage(
      {
        type: 'bc_zChat',
        data: { type: 'endRedirection', data: null },
      },
      '*',
    );
  }
}
