import { Injectable } from '@angular/core';
import { WidgetComponent } from '../widget/widget.component';
import { Message } from '../model/message';
import {
  RHInit,
  RHType,
  HandlerData,
  ZendeskHandlerData,
  FrontappHandlerData,
  EasichatHandlerData,
} from '../model/Session';
import { User } from '../model/user';
import { RedirectZendeskService } from './redirect-zendesk.service';
import { RedirectFrontappService } from './redirect-frontapp.service';
import { MultiTabService } from './multi-tab.service';
import { RedirectEasichatService } from './redirect-easichat.service';
import { LoggerService } from './logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(
    private logger: LoggerService,
    public redirectZendeskService: RedirectZendeskService,
    public redirectFrontappService: RedirectFrontappService,
    public redirectEasichatService: RedirectEasichatService,
    public multiTabService: MultiTabService,
  ) {}

  public initialized = false;

  public type: RHType;

  public init(initJson: RHInit, widget: WidgetComponent) {
    switch (initJson.type) {
      case RHType.ZENDESK:
        this.type = RHType.ZENDESK;
        this.initialized = true;
        this.redirectZendeskService.init(initJson, widget);
        break;
      case RHType.FRONTAPP:
        this.type = RHType.FRONTAPP;
        this.initialized = true;
        this.redirectFrontappService.init(initJson, widget);
        break;
      case RHType.EASICHAT:
        this.type = RHType.EASICHAT;
        this.initialized = true;
        this.redirectEasichatService.init(initJson, widget);
        break;
      default:
        this.initialized = false;
        this.logger.debug(`Unknown redirect type: ${initJson.type}`);
        break;
    }
  }

  public onMessageCall(m: Message) {
    /* this.tabUtilsService.callOnce(
      `redirectOnMessage_${m.uuid}`, this.onMessage.bind(this), m, 3000,
    ); */
    if (this.multiTabService.getIsActiveTab()) {
      this.onMessage(m);
    }
  }

  public onMessage(m: Message) {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        this.redirectZendeskService.onMessage(m);
        break;
      case RHType.FRONTAPP:
        this.redirectFrontappService.onMessage(m);
        break;
      case RHType.EASICHAT:
        this.redirectEasichatService.onMessage(m);
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }

  public onRhDataChangedCall(rhData: HandlerData) {
    /* this.tabUtilsService.callOnce(
      'redirectOnRhDataChanged', this.onRhDataChanged.bind(this), rhData, 3000,
    ); */
    if (this.multiTabService.getIsActiveTab()) {
      this.onRhDataChanged(rhData);
    }
  }

  public onRhDataChanged(rhData: HandlerData) {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        this.redirectZendeskService.onRhDataChanged(rhData as ZendeskHandlerData);
        break;
      case RHType.FRONTAPP:
        this.redirectFrontappService.onRhDataChanged(rhData as FrontappHandlerData);
        break;
      case RHType.EASICHAT:
        this.redirectEasichatService.onRhDataChanged(rhData as EasichatHandlerData);
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }

  public sendHistoryCall(history: Message[], ip?: string) {
    /* this.tabUtilsService.callOnce(
      'redirectSendHistory', this.sendHistory.bind(this), history, 3000,
    ); */
    if (this.multiTabService.getIsActiveTab()) {
      if (ip) {
        this.sendHistory(history, ip);
      } else {
        this.sendHistory(history);
      }
    }
  }

  public sendHistory(history: Message[], ip?: string) {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        if (ip) {
          this.redirectZendeskService.sendHistory(history, ip);
        } else {
          this.redirectZendeskService.sendHistory(history);
        }

        break;
      case RHType.FRONTAPP:
        this.redirectFrontappService.sendHistory(history);
        break;
      case RHType.EASICHAT:
        this.redirectEasichatService.sendHistory(history);
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }

  public setUserCall(user: User) {
    /* this.tabUtilsService.callOnce(
      'redirectSetUser', this.setUser.bind(this), user, 3000,
    ); */
    if (this.multiTabService.getIsActiveTab()) {
      this.setUser(user);
    }
  }

  public setUser(user: User) {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        this.redirectZendeskService.setUser(user);
        break;
      case RHType.FRONTAPP:
        this.redirectFrontappService.setUser(user);
        break;
      case RHType.EASICHAT:
        this.redirectEasichatService.setUser(user);
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }

  public hrefChanged(href: string, pageName: string) {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        this.redirectZendeskService.hrefChanged(href, pageName);
        break;
      case RHType.FRONTAPP:
        this.redirectFrontappService.hrefChanged(href, pageName);
        break;
      case RHType.EASICHAT:
        this.redirectEasichatService.hrefChanged(href, pageName);
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }

  public visitorTyping(isTyping: boolean) {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        this.redirectZendeskService.visitorTyping(isTyping);
        break;
      case RHType.FRONTAPP:
        this.redirectFrontappService.visitorTyping(isTyping);
        break;
      case RHType.EASICHAT:
        this.redirectEasichatService.visitorTyping(isTyping);
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }

  public endRedirection() {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        this.redirectZendeskService.endRedirection();
        break;
      case RHType.FRONTAPP:
        this.redirectFrontappService.endRedirection();
        break;
      case RHType.EASICHAT:
        this.redirectEasichatService.endRedirection();
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }
  public addTags(tags: string[]) {
    if (!this.initialized) {
      return;
    }
    switch (this.type) {
      case RHType.ZENDESK:
        this.redirectZendeskService.addTag(tags);
        break;
      case RHType.FRONTAPP:
      case RHType.EASICHAT:
        // Doing nothing since tags are not added this way
        break;
      default:
        this.logger.debug(`Unknown redirect type: ${this.type}`);
        break;
    }
  }
}
